import { normalize } from 'normalizr'
import {
  RESET_ENTITIES,
  resetEntities
} from './actions'

const INITIAL_STATE = {
  analystsData: {},
  pagination: {}
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_ENTITIES:
      if (!action.payload.managedAnalysts) return state

      return {
        ...state,
        ...action.payload.managedAnalysts
      }

    default:
      return state
  }
}

export function getManagedAnalysts (analystID, params = {}) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi.getManagedAnalysts(analystID, params).then(function (response) {
      const data = normalize(response.managedAnalysts, [schema.managedAnalysts])

      dispatch(
        resetEntities({
          managedAnalysts: {
            analystsData: { ...data.entities.managedAnalysts },
            pagination: { ...response.pagination }
          }
        })
      )

      return response
    })
  }
}
