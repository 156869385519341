import { schema } from 'normalizr'

export const task = new schema.Entity('tasks')
export const client = new schema.Entity(
  'clients',
  {},
  {
    idAttribute: function (value) {
      return value.uuid
    }
  }
)
export const chatMessage = new schema.Entity('chatMessages')
export const accessCode = new schema.Entity('accessCodes')
export const trigger = new schema.Entity('triggers')
export const analyst = new schema.Entity('analysts')
export const managedAnalysts = new schema.Entity('managedAnalysts')
export const qualitativeMetrics = new schema.Entity(
  'qualitativeMetrics',
  {},
  {
    idAttribute: function (value) {
      return value.analystLevel
    }
  }
)
export const program = new schema.Entity('programs')
export const ticket = new schema.Entity('tickets')
export const report = new schema.Entity('reports')
export const messageDraft = new schema.Entity('messageDrafts')
export const calendar = new schema.Entity('calendars')
export const calendarEvent = new schema.Entity('calendarEvents')
export const bodyCompositionReading = new schema.Entity(
  'bodyCompositionReadings'
)
export const team = new schema.Entity('teams')
export const programReflection = new schema.Entity('programReflections')
export const accessPolicy = new schema.Entity(
  'accessPolicies',
  {},
  {
    idAttribute: function (value) {
      return value.name
    }
  }
)
export const mealPlan = new schema.Entity('mealPlans')
export const programInduction = new schema.Entity(
  'programInductions',
  {},
  {
    idAttribute: function (value) {
      return value.clientID
    }
  }
)
export const rawReading = new schema.Entity('rawReadings')
export const coachingMembers = new schema.Entity('coachingMembers')
export const dataPoints = new schema.Entity('dataPoints')

export const TICKET_STATUSES = {
  OPEN: 'open',
  RESOLVED: 'resolved'
}

export const EVENT_TYPES = {
  'task.updated': 'Task Updated',
  'task.created': 'Task Created',
  'task.deleted': 'Task Deleted',
  'ticket_message.created': 'Chat Message Sent',
  'program.updated': 'Program Updated',
  'program.created': 'Program Created'
}

export const METRICS = [
  'Avg app engagement',
  'Successful first tests',
  'Successful program changes'
]

export const ANALYST_METRICS = [
  'Approved revisions',
  'Rejected revisions with minor edit',
  'Rejected revisions with major edit'
]

export const TASK_TYPES = [
  'Fix incorrect data',
  'Follow-up later',
  'Send exercise content',
  'Send food ideas',
  'Engagement follow-up',
  'Manager help',
  'Alarm symptom'
]

export const RESTRICTED_TASK_TYPES = ['Other']

export const TASK_ROLES = ['specialist', 'ambassador']

export const SUPPORT_CHANNELS = {
  chat: 'Chat',
  invoy_store: 'Invoy Store',
  conference_call: 'Conference Call Events',
  call_from_portal: 'Direct Call via Portal',
  call_from_phone: 'Direct Call from any phone',
  ship_to_home_address: 'Ship to home address'
}

export const PROGRAM_REFLECTION_TYPES = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  ANALYST_FEEDBACK: 'analyst_feedback'
}
